import { Component, Input } from '@angular/core';
import { ChatbotModalComponent } from '@app/shared/customer-service/chatbot';
import { NewModal, RelationInterface } from 'outshared-lib';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-entry',
    templateUrl: './chatbot-entry.component.html',
    styleUrls: ['./chatbot-entry.component.scss'],
})
export class ChatbotEntryComponent {
    @Input() relation!: RelationInterface;
    @Input() isOnDarkBackground: boolean = false;
    @Input() isSecondary: boolean = false;

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private newModal: NewModal,
    ) {
    }

    public onQuestion(question: string): void {
        this.newModal.open<ChatbotModalComponent>(ChatbotModalComponent, { data: question });
    }

    public onOpen(): void {
        this.newModal.open<ChatbotModalComponent>(ChatbotModalComponent);
    }
}
