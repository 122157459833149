<div
    class="ins-chatbot-entry"
    [class.ins-chatbot-entry--secondary]="isSecondary"
>
    <wuc-chatbot-mini
        buttonText="Verstuur"
        placeholder="Typ hier uw onderwerp..."
        [isOnDarkBackground]="isOnDarkBackground"
        [maxNumberOfCharacters]="150"
        maxNumberOfCharactersText="(max 150 tekens)"
        [onlyEmitOpen]="isSecondary"
        (question)="onQuestion($event)"
        (userOpenClick)="onOpen()"
    >
        <div
            slot="intro"
            class="ins-chatbot-entry__intro"
        >
            <ng-container *ngIf="!relation">Ik ben de chatbot. Waar gaat uw vraag over?</ng-container>

            <ng-container *ngIf="relation">
                Hallo,
                <ng-container [ngSwitch]="relation.gender_code">
                    <ng-container *ngSwitchCase="'M'">meneer&#32;</ng-container>
                    <ng-container *ngSwitchCase="'V'">mevrouw&#32;</ng-container>
                </ng-container>
                <span data-hj-suppress>{{ relation.middle_name_initials }} {{ relation.surname }}.</span>
                Ik ben de chatbot. Waar gaat uw vraag over?
            </ng-container>
        </div>
    </wuc-chatbot-mini>
</div>
