import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CharCounterComponent } from './char-counter.component';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CharCounterComponent
    ],
    exports: [
        CharCounterComponent
    ],
    providers: [],
})
export class CharCounterModule { }
