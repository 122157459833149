import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-entry-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ins-chatbot-entry
            [relation]="relation$ | async"
            [isOnDarkBackground]="isOnDarkBackground"
            [isSecondary]="isSecondary"
        >
        </ins-chatbot-entry>
    `,
})
export class ChatbotEntryContainer {
    @Input() isOnDarkBackground: boolean = false;
    @Input() isSecondary: boolean = false;

    public readonly relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();

    constructor(
        private relationDataService: RelationDataService,
    ) { }
}
