import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    WucChatbotAnswerInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotEscalationInterface,
} from '@inshared/website-ui-components';
import { ChatbotFaqList } from 'outshared-lib';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-faq',
    templateUrl: 'chatbot-faq.component.html',
})
export class ChatbotFaqComponent {
    @Input() public caption?: string;
    @Input() public list!: ChatbotFaqList;
    @Input() public answers!: { [key: number]: WucChatbotAnswerInterface };
    @Input() public escalation!: WucChatbotEscalationInterface;

    @Output() readonly selectQuestion: EventEmitter<number> = new EventEmitter();
    @Output() readonly feedback: EventEmitter<WucChatbotEscalationFeedbackInterface> = new EventEmitter();

    public onSelectQuestion(questionId: number): void {
        this.selectQuestion.emit(questionId);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.feedback.emit(feedback);
    }

}
