import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CharCounterModule } from '@app/shared/char-counter/char-counter.module';
import { WucChatbotMiniModule } from '@inshared/website-ui-components';
import { NewModalModule } from 'outshared-lib';
import { DirectivesModule } from '../../../directives/directives.module';
import { ChatbotEntryComponent } from './chatbot-entry.component';
import { ChatbotEntryContainer } from './chatbot-entry.container';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
        NewModalModule,
        ReactiveFormsModule,
        CharCounterModule,
        DirectivesModule,
        WucChatbotMiniModule,
    ],
    declarations: [
        ChatbotEntryContainer,
        ChatbotEntryComponent,
    ],
    exports: [
        ChatbotEntryContainer,
    ],
})
export class ChatbotEntryModule { }
