import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsAccordionModule } from '@app/shared/ux/accordion/details-accordion.module';
import { WucAccordionModule, WucCardModule, WucChatbotAnswerModule } from '@inshared/website-ui-components';
import { ChatbotFaqComponent } from './chatbot-faq.component';
import { ChatbotFaqContainer } from './chatbot-faq.container';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
        DetailsAccordionModule,
        WucAccordionModule,
        WucCardModule,
        WucChatbotAnswerModule,
    ],
    declarations: [
        ChatbotFaqComponent,
        ChatbotFaqContainer,
    ],
    exports: [
        ChatbotFaqContainer,
    ],
})
export class ChatbotFaqModule { }
