<div class="faq" *ngIf="list?.questions?.length">
    <h3>{{ caption || 'Meestgestelde vragen' }}</h3>
    <wuc-accordion
        [onDark]="true"
    >
        <wuc-accordion-item
            *ngFor="let question of list.questions"
            (clicked)="onSelectQuestion(question.id)"
            [accordionTitle]="question.question"
        >
            <wuc-card cardStyle="secondary" borderWidth="none" padding="medium">
                <div *ngIf="answers[question.id] as answer" content>
                    <wuc-chatbot-answer
                        [answer]="answer"
                        [escalation]="escalation"
                        (feedback)="onFeedback($event)"
                    ></wuc-chatbot-answer>
                </div>
            </wuc-card>
        </wuc-accordion-item>
    </wuc-accordion>
</div>
