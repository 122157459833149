import { ChangeDetectorRef, Component, Inject, Input, OnChanges } from '@angular/core';
import { LocalStorageService } from '@app/core/storage/universal.inject';
import { InsChatbotService } from '@app/shared/customer-service/chatbot/chatbot.service';
import {
    WucChatbotAnswerInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotEscalationInterface,
} from '@inshared/website-ui-components';
import { ChatbotFaqList, ProgressService } from 'outshared-lib';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-faq-container',
    template: `
        <ins-chatbot-faq
            [caption]="caption"
            [list]="list$ | async"
            [answers]="answers"
            [escalation]="escalation"
            (selectQuestion)="getFaqAnswer($event)"
            (feedback)="onFeedback($event)"
        ></ins-chatbot-faq>
    `,
})
export class ChatbotFaqContainer implements OnChanges {
    @Input() public caption: string;
    /**
     When set to true ProgressService will be used to determie classificationId, classificationName and step
     */
    @Input() public useProgressServiceForClassification: boolean = false;
    /**
     * Will be ignored if useProgressServiceForClassification set to true
     */
    @Input() public classificationId: number | null = null;
    /**
     * Will be ignored if useProgressServiceForClassification set to true
     */
    @Input() public classificationName: string;
    /**
     * Will be ignored if useProgressServiceForClassification set to true
     */
    @Input() public step: number;
    public list$: Observable<ChatbotFaqList>;
    public answers: { [key: number]: WucChatbotAnswerInterface } = {};

    public escalation: WucChatbotEscalationInterface = {
        usefulness: {
            title: 'Heeft dit antwoord geholpen?',
            buttonYesText: 'Ja, prima',
            buttonNoText: 'Nee, helaas',
        },
        thanksText: 'Bedankt voor uw feedback',
    };

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private readonly chatbotService: InsChatbotService,
        private readonly progressService: ProgressService,
        private cdr: ChangeDetectorRef,
        @Inject(LocalStorageService) private localStorage: Storage,
    ) {
    }

    public ngOnChanges(): void {
        if (this.useProgressServiceForClassification) {
            const productId$ = this.progressService.productId$;
            const step$ = this.progressService.index$.pipe(
                map((index: number): number => index + 1),
                filter((currentStep: number): boolean => currentStep > 0),
            );
            this.list$ = combineLatest([productId$, step$]).pipe(
                switchMap(([productId, currentStep]): Observable<ChatbotFaqList> => {
                    const classification = InsChatbotService.getClassification(productId);
                    return this.chatbotService.getFaqList$(
                        classification.id,
                        classification.name,
                        currentStep,
                    );
                }),
            );
            return;
        } else {
            if (this.classificationId || this.classificationName) {
                this.list$ = this.chatbotService.getFaqList$(
                    this.classificationId,
                    this.classificationName,
                    this.step,
                );
            }
        }

    }

    public getFaqAnswer(faqId: number): void {
        this.chatbotService.getFaqAnswer$(faqId)
            .subscribe((answer: WucChatbotAnswerInterface): void => {
                this.answers[faqId] = answer;
                this.cdr.detectChanges();
            });
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.chatbotService.sendFeedback(feedback);
    }

}
